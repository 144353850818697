<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-2">mdi-chart-areaspline</v-icon>Product Sold
        </h2>
    </v-flex>
    <v-flex class="mx-6">
        <alert-message v-if="msg" :error="msg"></alert-message>
    </v-flex>
    <v-container>
        <v-flex>
            <v-layout align-center wrap>
                <v-flex xs4 sm3 class="ml-3">
                    <input-date @clear="startDateCleared=true" v-model="startDate" label="Start Date"></input-date>
                </v-flex>
                <v-flex xs4 sm3 class=" ml-3 mr-5">
                    <input-date @clear="endDateCleared=true" v-model="endDate" label="End Date"></input-date>
                </v-flex>
                <v-flex>
                    <v-btn mb-6 outlined :style="themeInverted" @click="generateReport()">Generate</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex>
            <v-container>
                <v-layout v-if="items.length>0" column>
                    <v-flex text-center>
                        <h2 v-if="startDateCleared||startDate>=endDate" class="report-heading">Products Sold Report till {{endDate}}</h2>
                        <h2 v-else class="report-heading">Products Sold Report - {{startDate}} to {{endDate}}</h2>
                    </v-flex>
                    <v-flex text-right>
                        <export-to-csv v-if="startDateCleared||startDate>=endDate" :data="items" :fileName="`product-sold-report-till-${endDate}`"></export-to-csv>
                        <export-to-csv v-else :data="items" :fileName="`product-sold-report-${startDate}-to-${endDate}`"></export-to-csv>
                        <export-to-xls v-if="startDateCleared||startDate>=endDate" :data="items" :fileName="`product-sold-report-till-${endDate}`"></export-to-xls>
                        <export-to-xls v-else :data="items" :fileName="`product-sold-report-${startDate}-to-${endDate}`"></export-to-xls>
                    </v-flex>
                    <v-simple-table>
                    <thead>
                        <tr>
                            <th>Code</th>
                            <th>Product</th>
                            <th class="text-right">Sold Items</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" :key="item.code">
                            <td>{{item._id}}</td>
                            <td>{{item.name}}</td>
                            <td class="text-right">{{item.soldStock}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
                </v-layout>
                <v-flex text-center>
                    <progress-bar :show="loading"></progress-bar>
                </v-flex>
            </v-container>
        </v-flex>
    </v-container>
</v-layout>
</template>

<script>
import appConstants from '@/utils/appConstants'
import ExportToCsv from '@/components/ExportToCsv'
import ExportToXls from '@/components/ExportToXls'
import moment from 'moment'
export default {
    components: {
        ExportToCsv,
        ExportToXls
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            startDateCleared:false,
            endDateCleared:false,
            items: [],
            loading: false,
            downloadType: 'csv',
        }
    },
    mounted () {
        this.startDateCleared=true
        this.generateReport();
    },
    methods: {
        async generateReport() {
            try {
                this.loading = true
                if(this.startDateCleared&&this.endDateCleared){
                    this.endDate=moment(new Date()).format('DD/MM/YYYY')
                    this.items = await this.getItem(appConstants.PRODUCT_SOLD_REPORT + "?endDate=" + this.$options.filters.defaultJsonDateFormat(this.endDate))
                    this.startDateCleared=false
                    this.endDateCleared=false
                    this.startDate=null
                }
                else if(this.startDateCleared){
                    this.items = await this.getItem(appConstants.PRODUCT_SOLD_REPORT + "?endDate=" + this.$options.filters.defaultJsonDateFormat(this.endDate))
                    this.startDateCleared=false
                    this.startDate=null
                    this.endDateCleared=false
                }
                else if(!this.startDateCleared&&this.endDateCleared){
                    this.endDate=moment(new Date()).format('DD/MM/YYYY')
                    this.items=await this.getItem(appConstants.PRODUCT_SOLD_REPORT + "?startDate=" + this.$options.filters.defaultJsonDateFormat(this.startDate) + "&endDate=" + this.$options.filters.defaultJsonDateFormat(this.endDate))
                    this.endDateCleared=false
                }
                else
                    this.items = await this.getItem(appConstants.PRODUCT_SOLD_REPORT + "?startDate=" + this.$options.filters.defaultJsonDateFormat(this.startDate) + "&endDate=" + this.$options.filters.defaultJsonDateFormat(this.endDate))
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false
            }
        }
    },
}
</script>

<style scoped>
.report-heading{
    font-size: 1.2rem !important;
    font-weight: 500
}
</style>
